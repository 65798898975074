import { fetchUserDetails, updateMyProfile, updatePassword } from '../../services/user';
import { userProfileSchema, validate } from '../../services/validation';

export default {
  name: 'home',
  data() {
    return {
      fetchingData: false,
      updatingUserData: false,
      updatingPassword: false,
      userDetails: null,
      updatePassForm: {
        currentPass: '',
        pass: '',
        checkPass: ''
      },
      userFormError: null,
      passRules: {
        currentPass: [{ required: true, message: 'Please provide your current password.', trigger: 'blur' }],
        pass: [{ required: true, message: 'New Pass can not be null', trigger: 'blur' }],
        checkPass: [{ required: true }]
      },
      labelPosition: 'left',
      userDetailsForm: {
        firstName: '',
        lastName: '',
        email: '',
        companyName: ''
      },
      userFormMode: 'normal'
    };
  },
  methods: {
    fetchUserData() {
      var params = {
        userId: this.$store.state.token.userId
      };
      this.fetchingData = true;
      fetchUserDetails(params)
        .then((result) => {
          if (result.data.success) {
            this.userDetails = result.data.data;
            this.userDetailsForm.firstName = result.data.data.first_name;
            this.userDetailsForm.lastName = result.data.data.last_name;
            this.userDetailsForm.email = result.data.data.email;
            this.userDetailsForm.companyName = result.data.data.company_name;

            this.userDetailsCopy = JSON.parse(JSON.stringify(this.userDetails));
            this.userDetailsFormCopy = JSON.parse(JSON.stringify(this.userDetailsForm));

            this.fetchingData = false;
          } else {
            //this.$toast.customer(result.data.message);
            this.fetchingData = false;
          }
        })
        .catch(() => {
          this.errorToast('Something is wrong, please contact support.');
          this.fetchingData = false;
        });
    },

    updateMyProfile() {
      // Validate form using joi
      let validationResult = validate(this.userDetailsForm, userProfileSchema);
      if (validationResult.error && validationResult.error.details.length > 0) {
        this.userFormError = validationResult.error.details[0].message;
        return;
      }

      const params = this.userDetailsForm;
      if (!params.firstName || !params.lastName) {
        this.errorToast('Please provide correct input.', 10000);
        return;
      }
      this.updatingUserData = true;
      updateMyProfile(params)
        .then((result) => {
          if (!result.data.success) {
            this.fetchUserData();
          }
          this.successToast('Profile updated successfully.');
          this.userFormMode = 'normal';
          this.updatingUserData = false;
        })
        .catch((error) => {
          this.reportError(error);
          this.userFormMode = 'normal';
          this.errorToast('Something is wrong, please contact support.');
          this.updatingUserData = false;
        });
    },

    cancelProfileUpdate() {
      this.userFormMode = 'normal';
      this.userFormError = null;
      this.userDetails = JSON.parse(JSON.stringify(this.userDetailsCopy));
      this.userDetailsForm = JSON.parse(JSON.stringify(this.userDetailsFormCopy));
    },

    updatePassword() {
      const params = {
        oldPass: this.updatePassForm.currentPass,
        password: this.updatePassForm.checkPass
      };
      if (this.updatePassForm.checkPass !== this.updatePassForm.pass) {
        this.errorToast("Password didn't matched.", 10000);
        return;
      }

      if (!this.updatePassForm.checkPass || !this.updatePassForm.pass) {
        this.errorToast('Password fields can not be empty.', 10000);
        return;
      }
      this.updatingPassword = true;
      updatePassword(params)
        .then((result) => {
          if (result.data.success) {
            this.successToast('Password updated successfully.');
            this.updatePassForm.currentPass = '';
            this.updatePassForm.pass = '';
            this.updatePassForm.checkPass = '';
          }
          this.updatingPassword = false;
          if (result.data.message) {
            this.errorToast(result.data.message, 10000);
          }
        })
        .catch((error) => {
          this.reportError(error);
          this.errorToast('Something is wrong, please contact support.');
          this.updatingPassword = false;
        });
    }
  },
  created() {
    this.fetchUserData();
  }
};
