<template>
  <div id="profilePage">
    <div class="headerTitle">My Profile</div>

    <el-tabs class="tabs" v-if="userDetails" type="border-card">
      <!-- prfile details -->
      <el-tab-pane label="Account Details" style="padding: 30px 150px 0 60px">
        <div class="errorMsg" v-if="userFormError">{{ userFormError }}</div>

        <el-form :label-position="labelPosition" label-width="100px" :model="userDetailsForm">
          <el-form-item label="First Name">
            <el-input v-model="userDetailsForm.firstName" :disabled="userFormMode == 'normal'"> </el-input>
          </el-form-item>

          <el-form-item label="Last Name">
            <el-input v-model="userDetailsForm.lastName" :disabled="userFormMode == 'normal'"> </el-input>
          </el-form-item>

          <el-form-item label="Email">
            <el-input v-model="userDetailsForm.email" :disabled="true"> </el-input>
          </el-form-item>
          <el-form-item style="text-align: center">
            <el-button type="primary" @click="userFormMode = 'edit'" plain v-if="userFormMode == 'normal'"> Edit</el-button>
            <el-button type="success" @click="updateMyProfile()" :loading="updatingUserData" v-if="userFormMode == 'edit'">Save Changes</el-button>
            <el-button type="danger" @click="cancelProfileUpdate()" v-if="userFormMode == 'edit' && !updatingUserData">Cancel Changes</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>

      <!-- new password edit -->
      <el-tab-pane label="Change Password" style="padding: 30px 150px 0 60px">
        <el-form :label-position="labelPosition" :model="updatePassForm" status-icon :rules="passRules" ref="updatePassForm" label-width="160px" class="demo-ruleForm" @submit.native.prevent>
          <el-form-item label="Current Password" :rules="passRules.currentPass">
            <el-input type="password" v-model="updatePassForm.currentPass" auto-complete="off"> </el-input>
          </el-form-item>
          <el-form-item label="New Password" :rules="passRules.pass">
            <el-input type="password" v-model="updatePassForm.pass" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item @keyup.enter.native="updatePassword" label="Verify New Password" :rules="passRules.checkPass">
            <el-input type="password" v-model="updatePassForm.checkPass" auto-complete="off"> </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="updatePassword()" :loading="updatingPassword">Change password</el-button>
            <!-- <el-button @click="resetForm('updatePassForm')">Reset</el-button> -->
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<style lang="scss">
#profilePage {
  padding-left: 18%;
  padding-right: 18%;
  margin: auto;
  width: 80%;
  padding-top: 5vh !important;

  .headerTitle {
    font-size: 25px;
    margin-bottom: 3vh;
  }

  .tabs {
    .errorMsg {
      text-align: center;
      color: red;
      margin-bottom: 30px;
    }
  }
}
</style>

<script>
import ProfileComponent from './profileComponent';
export default ProfileComponent;
</script>
